<template>
  <v-app>
    <v-app-bar
      color="secondary"
      app
      clipped-left
      v-if="isLoggedIn"
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>{{ layoutData.headline }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items
        v-if="layoutData.buttons.length"
      >
        <v-btn
          v-for="(button, index) in layoutData.buttons"
          :key="index"
          @click="onButtonClick(button)"
          icon
        >
          <v-icon
          >
            {{ button.icon }}
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isLoggedIn"
      app
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      clipped
    >
      <!--
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="loggedInData.avatar"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      -->
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedMenuItem"
          color="primary"
        >
          <v-list-item
            v-for="menuItem in menuItems"
            :key="menuItem.label"
            :to="{ name: menuItem.label }"
          >
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logga ut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--overlay-->
    <app-overlay></app-overlay>
    <v-main>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view @layoutData="onLayoutData" :act="viewAct" @actConsidered="onActConsidered"></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import SnackBar from './components/SnackBar'
import Overlay from './components/Overlay'

export default {
  name: 'App',
  components: {
    'app-snack-bar': SnackBar,
    'app-overlay': Overlay
  },
  data: () => ({
    selectedMenuItem: 0,
    drawer: null,
    layoutData: {
      headline: '',
      buttons: [],
    },
    layoutData_default: null,
    viewAct: '',
    menuItems: [
      {
        label: 'home',
        icon: 'mdi-home',
        name: 'Hem'
      },
      {
        label: 'nginx',
        icon: 'mdi-server',
        name: 'Nginx'
      }
    ]
  }),
  computed: {
    ...mapGetters(['isLoggedIn', 'loggedInData']),
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.replace({ name: 'login' })
        })
    },
    onLayoutData(layoutData) {
      this.layoutData = Object.assign({}, this.layoutData_default, layoutData);
    },
    onButtonClick(button) {
      console.log('App.vue onButtonClick')
      this.viewAct = button.act
    },
    onActConsidered() {
      this.viewAct = '';
    }
  },
  created() {
    this.layoutData_default = Object.assign({}, this.layoutData);
  }
};
</script>
