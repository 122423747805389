import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedInData: null,
    access_token: null,
    firstRouterHasLoaded: false,
    trace_ar: [],
    version: "0.0.1"
  },
  mutations: {
    SET_LOGGED_IN_DATA(state, loggedInData = null) {
      state.loggedInData = loggedInData
    },
    SET_ACCESS_TOKEN(state, access_token = null) {
      state.access_token = access_token
    },
    SET_FIRST_ROUTER_HAS_LOADED(state) {
      state.firstRouterHasLoaded = true
    },
    PREPEND_TRACE(state, output) {
      state.trace_ar.unshift(output)
    }
  },
  getters: {
    version: state => {
      return state.version
    },
    traceOutput: state => {
      return state.trace_ar.length == 0 ? '' : '<ul><li>' + state.trace_ar.join('</li><li>') + '</li></ul>'
    },
    isLoggedIn: state => {
      return !!(state.loggedInData)
    },
    access_token: state => {
      return state.access_token
    },
    loggedInData: (state, getters) => {
      if (getters.isLoggedIn) {
        return state.loggedInData
      }
      return null
    },
  },
  actions: {
    setFirstRouterHasLoaded({ commit }) {
      commit('SET_FIRST_ROUTER_HAS_LOADED', true)
    },
    setAccessToken({ commit }, access_token) {
      commit('SET_ACCESS_TOKEN', access_token)
    },
    onAuth({ commit }, data) {
      commit('SET_LOGGED_IN_DATA', data.loggedInData)
      if (data.access_token) {
        //access_token is not sent when getting logged in data, switching accounts e.g
        //store access_token in store
        commit('SET_ACCESS_TOKEN', data.access_token)
        //store access_token in localStorage
        setLocalStorage('access_token', data.access_token)
      }
      return
    },
    prependTrace({ commit }, output) {
      commit('PREPEND_TRACE', output)
    },
    logout({ commit }) {
      commit('SET_LOGGED_IN_DATA')
      commit('SET_ACCESS_TOKEN')
      deleteLocalStorage('access_token')
    }
  }
})
//local storage functions
function setLocalStorage(key, value) {
  //ensure data as an JSON string
  let data = (typeof value === 'string') ? value : JSON.stringify(value)
  //store value in localStorage so it will be persistent over page reloads
  localStorage.setItem(key, data);
}
function deleteLocalStorage(key) {
  //remove from local storage
  localStorage.removeItem(key)
}
