import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.base,
        secondary: colors.blueGrey.darken1,
        accent: colors.purple.base,
        bad: colors.red.darken1,
        good: colors.green.darken1,
        neutral: colors.grey.darken1
      },
    },
  },
});
