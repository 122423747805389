import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import axios from 'axios'
import { urlMixin } from '../mixins/url'

Vue.use(VueRouter)

const routes = [
  //default
  {
    path: '/',
    redirect: { name: 'home' }
  },
  //wildcard catcher
  {
    path: '*',
    redirect: { name: 'home' }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    beforeEnter:(to, from, next) => {
      if (!store.getters.isLoggedIn) {
        //if logged in, go to home
        next({ name: 'login' })
      }
      next()
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    beforeEnter:(to, from, next) => {
      if (store.getters.isLoggedIn) {
        //if logged in, go to home
        next({ name: 'home' })
      }
      next()
    }
  },
  {
    path: '/nginx',
    name: 'nginx',
    component: () => import('../views/Nginx.vue'),
    beforeEnter:(to, from, next) => {
      if (!store.getters.isLoggedIn) {
        //if logged in, go to home
        next({ name: 'login' })
      }
      next()
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//make sure we load possible localStorage before we enter a route (mainly on first load)
router.beforeEach((to, from, next) => {
  console.log('router beforeEach')
    if (store.state.firstRouterHasLoaded) {
      //this is not initial router load, just go on
      next()
    } else {
      //set firstRouterHasLoaded to true so we do not consider this again
      store.dispatch('setFirstRouterHasLoaded')
      //this is the initial router load
      console.log("on first router load")
      //get possible access_token from localstorage
      let access_token = localStorage.getItem('access_token')
      if (!access_token) {
        console.log('has no access_token', access_token)
        //not logged in - proceed and let the respective "route guards" determine where to go
        next()
      } else {
        //store access_token in store
        console.log('has access_token', access_token)
        store.dispatch("setAccessToken", access_token)
        //try to fetch loggedInData for access_token
        let config = {
          headers: {
            'Authorization': 'Bearer ' + access_token
          }
        }
        let url = urlMixin.methods.url_composeApiUrl('/core/auth/get-logged-in-data')
        axios.get(url, config)
          .then(res => {
            console.log('get-logged-in-data response', res)
            if (res.status == 200) {
              //success - call action onAuth in store
              let dataToDispatch = {
                loggedInData: res.data.loggedInData
              }
              store.dispatch('onAuth', dataToDispatch)
                .then(() => {
                  //ok to go to next
                  next()
                })
            } else {
              //non success - make sure we logout and go home
              store.dispatch('logout')
                .then(() => {
                  //go home
                  next({ name: 'home' })
                })
            }
          })
          .catch(error => {
            console.log(error.toString())
            //error - logout and go home
            store.dispatch('logout')
              .then(() => {
                //go home
                next({ name: 'home' })
              })
          })
      }
    }
})

export default router
