export const urlMixin = {
  methods: {
    url_composeApiUrl(relPath, queryStringData_ar = []) {
      //we cant include api name in axios.defaults.baseURL, cause we want to eval the host before we set it
      //set address
      let address;
      if (process.env.NODE_ENV == 'development') {
        //when npm run serve on localhost
        address = 'http://localhost:3000'
      } else {
        //when deployed prod
        address = 'https://vajper-api.webomaten.se'
      }
      address += relPath;
      return this.url_composeCachelessUrl(address, queryStringData_ar, true)
    },
    url_composeCachelessUrl(address, queryStringData_ar = []) {
      return this.url_composeUrl(address, queryStringData_ar, true)
    },
    url_composeVersionForUrl(version) {
      return version.replace(/\./g, '_')
    },
    url_composeUrl(address, queryStringData_ar = [], cacheless) {
      if (cacheless) {
        queryStringData_ar.push({
          name: 'nocache',
          value: new Date().getTime()
        })
      }
      if (!queryStringData_ar) {
        return address
      }
      return this.url_appendQueryString(address, queryStringData_ar)
    },
    url_appendQueryString(address, queryStringData_ar) {
      if (!queryStringData_ar) {
        return address
      }
      let queryString = this.url_convertQueryStringDataArrayQueryString(queryStringData_ar)
      return address + '?' + queryString

    },
    url_convertQueryStringDataArrayQueryString(queryStringData_ar) {
      let queryString_ar = []
      for (let queryStringData of queryStringData_ar) {
        let item = queryStringData.name
//        if (this.hasProperty(queryStringData, 'value')) {
          item += '=' + queryStringData.value
//        }
        queryString_ar.push(item)
      }
      return queryString_ar.join('&')
    }
  }
}
